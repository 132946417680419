(function() {
    angular.module('EntrakV5').controller('sceneController', sceneController);

    function sceneController($scope, $rootScope, $state, Service, KEY, APIKEY, Api) {
        console.log('sceneController');

        var caller = Api.createApiCaller();
        $rootScope.title = Service.translate("scene.title");

        $scope.btnStatus = {};

        $scope.scenes = null;
        $scope.selectedScene = null;

        var floors = [];
        $scope.floorMap = {};

    /* scene list view */
        //first load
        $rootScope.loadingPage = 1;
        $rootScope.getTenantId().then(function(tenantId){
            // if ($rootScope.tenantHasScene.indexOf($rootScope.currentUser.tenant.id) == -1){
            //     $state.go('dashboard');
            //     return;
            // }
            caller.call(Api.getScenePage(tenantId)).then(function(tenant){
                floors = tenant.zones;
                $scope.floorMap = Service.arrayToMap(floors);
                floors.forEach(function(f){
                    f.devices = f.gateways.reduce(function(arr, g){
                        return arr.concat(g.devices);
                    }, []);
                    delete f.gateways;
                });
                $scope.floorDropdown.setDataSource(new kendo.data.DataSource({
                    data: floors
                }));

                $rootScope.resetColorIndex();
                $scope.scenes = floors.reduce(function(arr, z){
                    return arr.concat(z.scenes);
                }, []);
                $scope.scenes.forEach(function(s){
                    s.color = $rootScope.getNextColor();
                });

                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        });

        $scope.floorDropdownOpt = {
            autoWidth: true,
            dataTextField: "name",
            dataValueField: "id"
        }

        $scope.logicDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("location.logicNA"),
                value: APIKEY.logic.noLogic,
            }, {
                text: Service.translate("location.logicGTE"),
                value: APIKEY.logic.greater,
            }, {
                text: Service.translate("location.logicLTE"),
                value: APIKEY.logic.lower,
            }],
            dataTextField: "text",
            dataValueField: "value"
        }
        $scope.createSceneData = {};
        $scope.createSceneWinOpt = {
            width: "700px",
            title: Service.translate("scene.win.titleCreate"),
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"],
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.showCreateSceneWin = function(){
            $scope.createSceneData.name = '';
            $scope.createSceneData.zoneId = $scope.floorDropdown.dataSource.data()[0].id;
            setTimeout(function(){
                $scope.createSceneWin.center().open();
            });
        }

        $scope.createScene = function(){
            caller.call(Api.createScene($scope.createSceneData.zoneId, $scope.createSceneData.name)).then(function(res){
                $rootScope.currentUser.tenant.scenes.push(res);
                res.color = $rootScope.getNextColor();
                $scope.scenes.push(res);
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler(true);
            });
            $scope.createSceneWin.close();
        }

        $scope.showEditScene = function(scene){
            $scope.selectedScene = scene;
            if (!$scope.selectedScene.configMap)
                $scope.selectedScene.configMap = Service.arrayToMap($scope.selectedScene.configs, "deviceId");
            
            $scope.editMapModel.markers = $scope.floorMap[scene.zoneId].devices;
            $scope.clearMapSelection();
        }

        $scope.activateScene = function(s){
            $rootScope.loadingPage++;
            caller.call(Api.activateScene(s.id)).then(function(res){
                res.color = s.color;
                Service.replaceArrItem($scope.scenes, res);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }
        $scope.suspendScene = function(s){
            $rootScope.loadingPage++;
            caller.call(Api.suspendScene(s.id)).then(function(res){
              res.color = s.color;
                Service.replaceArrItem($scope.scenes, res);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }
    /* scene list view */

    /* scene setting view */
        $scope.editMapModel = {};
        $scope.cancelEditScene = function(){
            $scope.selectedScene = null;
        }

        $scope.clearMapSelection = function(){
            $scope.editMapModel.markers.forEach(function(m){
                m.cssClass = $scope.selectedScene.configMap[m.id] ? 'on' : '';
                if ($rootScope.noSceneType(m.applicationType))
                    m.cssClass += " dim";
            });

            $scope.dimMarker = false;
            $scope.editMapModel.editingConfigData = {};
        }

        $scope.getDeviceCountInScene = function(){
            return Object.keys($scope.selectedScene.configMap).length;
        }
        $scope.filterDevice = function(){
            return function(d){
                return $scope.selectedScene.configMap[d.id] != null;
            }
        }

        $scope.clickMapMarker = function($i){
            var marker = $scope.editMapModel.markers[$i];
            if ($rootScope.noSceneType(marker.applicationType) || $scope.editMapModel.editingConfigData.deviceId === marker.id){
                return;
            } else if ($scope.editMapModel.editingConfigData.deviceId){
                $scope.clearMapSelection();
                $scope.selectDevice(marker);
            } else {
                $scope.selectDevice(marker);
            }
        }

        //1.click marker, 2.click device on right list
        $scope.selectDevice = function(device, $event){//assume device.applicationType != button/sensor
            if ($event){//click on list
                var target = angular.element($event.target);
                if (!target.hasClass("applicationType"))
                    target = target.parent();
                target.removeClass("hover");
            }

            $scope.dimMarker = true;
            device.cssClass = "on noDim";

            var config = $scope.selectedScene.configMap[device.id];
            $scope.editMapModel.editingConfigData = {
                id: (config ? config.id : null),
                deviceId: device.id,
                deviceSerial: device.serialId,
                type: device.applicationType,
                status: (config ? config.status : false),
                dimLv: (device.dimmable && config ? config.dimmingLevel : null),
            }
        }
        $scope.saveDeviceToScene = function(){
            $scope.editMapModel.editingConfigData.saving = true;
            var scene = $scope.selectedScene;
            var deviceId = $scope.editMapModel.editingConfigData.deviceId;
            var dimLv = $scope.editMapModel.editingConfigData.status ? $scope.editMapModel.editingConfigData.dimLv : null;
            var status = $scope.editMapModel.editingConfigData.type === APIKEY.applicationType.lightSensor ? null : $scope.editMapModel.editingConfigData.status;
            if ($scope.editMapModel.editingConfigData.id){
                var action = Api.updateConfig($scope.editMapModel.editingConfigData.id, status, dimLv);
            } else {
                var action = Api.createConfig(scene.id, deviceId, status, dimLv);
            }
            caller.call(action).then(function(res){
                var tmp = Service.getArrItem($rootScope.currentUser.tenant.scenes, scene.id).configs;
                Service.replaceArrItem(tmp, res, true);
                scene.configMap[deviceId] = res;
                $scope.clearMapSelection();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.editMapModel.editingConfigData.saving = false;
                $rootScope.generalErrorHandler(true);
            });
        }
        $scope.removeDeviceFromScene = function(){
            var configId = $scope.editMapModel.editingConfigData.id;
            if (!configId){
                $scope.clearMapSelection();
            } else {
                $scope.editMapModel.editingConfigData.saving = true;
                var scene = $scope.selectedScene;
                var deviceId = $scope.editMapModel.editingConfigData.deviceId;
                caller.call(Api.deleteConfig(configId)).then(function(res){
                    var tmp = Service.getArrItem($rootScope.currentUser.tenant.scenes, scene.id).configs;
                    Service.deleteArrItem(tmp, configId);
                    delete scene.configMap[deviceId];
                    $scope.clearMapSelection();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.editMapModel.editingConfigData.saving = false;
                    $rootScope.generalErrorHandler(true);
                });
            }
        }

        $scope.hoverListMarker = function(isHover, marker, $event){
            if ($scope.editMapModel.editingConfigData.id)
                return;
            var target = angular.element($event.target);//assume marker.applicationType != button/sensor
            if (!target.hasClass("applicationType"))
                target = target.parent();
            if (isHover){
                target.addClass("hover");
                marker.cssClass += " noDim";
                $scope.dimMarker = true;
            } else {
                target.removeClass("hover");
                marker.cssClass = marker.cssClass.replace("noDim", "").trim();
                $scope.dimMarker = false;
            }
        }

        $scope.statusDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("button.turnOn"),
                value: true,
            }, {
                text: Service.translate("button.turnOff"),
                value: false,
            }],
            dataTextField: "text",
            dataValueField: "value"
        }

        $scope.editSceneData = {};
        $scope.editSceneWinOpt = {
            width: "700px",
            title: Service.translate("scene.win.titleEdit"),
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"],
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.showEditSceneWin = function(){
            $scope.editSceneData.name = $scope.selectedScene.name;
            if ($scope.selectedScene.lightSensorConfig) {
                $scope.editSceneData.threshold = $scope.selectedScene.lightSensorConfig.threshold;
                $scope.editSceneData.logic = $scope.selectedScene.lightSensorConfig.condition;
            } else {
                $scope.editSceneData.threshold = "";
                $scope.editSceneData.logic = "";
            }
            $scope.editSceneData.floorName = $scope.floorMap[$scope.selectedScene.zoneId].name;
            setTimeout(function(){
                $scope.editSceneWin.center().open();
            });
        }
        $scope.editScene = function(){
            $scope.btnStatus.saving = true;
            var scene = $scope.selectedScene;
            caller.call(Api.updateScene(scene.id, $scope.editSceneData.name, $scope.editSceneData.threshold, $scope.editSceneData.logic)).then(function(res){
                $scope.editSceneWin.close();
                res.color = scene.color;
                Service.replaceArrItem($rootScope.currentUser.tenant.scenes, res);
                Service.replaceArrItem($scope.scenes, res);
                $scope.showEditScene(res);
                $scope.btnStatus.saving = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                $rootScope.generalErrorHandler(true);
            });
        }
        $scope.deleteScene = function(){
            $scope.btnStatus.saving = true;
            var scene = $scope.selectedScene;
            caller.call(Api.deleteScene(scene.id)).then(function(res){
                $scope.editSceneWin.close();
                Service.deleteArrItem($rootScope.currentUser.tenant.scenes, scene.id);
                Service.deleteArrItem($scope.scenes, scene);
                $scope.btnStatus.saving = false;
                if (scene === $scope.selectedScene)
                    $scope.cancelEditScene();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                $rootScope.generalErrorHandler(true);
            });
        }
    /* scene setting view */

        $scope.$on('$destroy', function() {
            console.log("sceneController destroy");
            caller.cancel();
        });
    }
})();
